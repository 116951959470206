// Body
$body-bg: #ececec;
$input-bg: #FFFFFF;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$offcanvas-horizontal-width:  50%;

$link-color: #8ba236;
