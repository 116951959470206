// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


.container-fluid {
  max-width: 1800px;
}


.bg-dark {
  background-color: black !important;
}

.bg-perrymans-green {
  background-color: $link-color;
}

.style_box {
  background:#eeeeee;
  border:1px solid #cccccc;
  padding:5px 10px;
}

.instruction {
  background-color: yellow;
  padding: 5px;
}

.modal-backdrop {
  z-index: 1037 !important;
}

.modal {
  z-index: 2000 !important;
}


